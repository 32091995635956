body.single-vacature .website-entrance {
  padding-bottom: 18rem;
  @include media(max-sm) {
    padding-bottom: 10rem;
  }
}

.jobPosting-content-section {
  .sidebar-left {
    align-items: start;
  }
  .sidebar {
    margin-top: -280px;
  }
  .jobPosting-contact {
    flex-direction: column;
    background-color: #f6923b;
    padding: 25px 20px;
    border-radius: 8px;
    @include media(lg) {
      position: sticky;
      top: 12rem;
    }
    h3 {
      color: white;
      margin-bottom: 0;
      font-weight: 500;
    }
    p {
      color: white;
    }
    ul, ol {
      color: white;
      list-style: inherit;
      margin: 0 1rem 1rem 1rem;
    }
  }
  //.featured-image-wrapper {
  //  position: relative;
  //  top: -130px;
  //  margin-bottom: -80px;
  //  @include media(md) {
  //    margin-bottom: -100px;
  //    top: -140px;
  //  }
  //  @include media(lg) {
  //    top: -200px;
  //    margin-bottom: -150px;
  //  }
  //  @include-media('2xl') {
  //    top: -250px;
  //    margin-bottom: -150px;
  //  }
  //}
  .jobPosting-content {
    margin-top: -280px;
    background-color: white;
    padding: 4rem;
    border-radius: 8px;
    line-height: 26px;
    .frame-intro {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 30px;
    }
    .attribute {
      margin: 15px 0;
      background-color: #191919;
      padding: 25px 20px;
      border-radius: 8px;
      h3 {
        color: white;
        margin-bottom: 0;
      }
      p {
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 0;
      }
    }
    .attributes {
      padding-bottom: 2rem;
    }
    .form-container {
      margin-top: 2rem;
      h2 {
        color: black;
      }
    }
  }
}

.content-anchor {
  display: block;
  position: relative;
  top: -12rem;
  visibility: hidden;
}
