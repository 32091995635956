/* STYLES */
.wrapper {
    padding: 2rem 0;
}

/* Block with images, gradient and text on the bottom, middle or on the top */
.img-holder {
    position: relative;
    display: block;
    &.triangle {
        &:before {
            content: '';
            position: absolute;
            left: -8px;
            top: 16px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 8px 0;
            border-color: transparent $color-alabaster transparent transparent;
        }
    }
    p {
        margin-bottom: 2px;
    }
    &.gradient {
        //@include gradient-vertical($start-color: rgba($color-white, .0), $end-color: rgba($color-black, 1), $start-percent: 60%, $end-percent: 100%);
        img {
            position: relative;
            z-index: -1;
        }
    }
    .label {
        position: absolute;
        top: 15px;
        left: 0;
        @include media('max-md') {
            top: 5px;
        }
    }
}
.img-text-holder {
    padding: 10px 15px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    &.middle {
        bottom: auto;
        top: 30%;
        @include media('max-sm') {
            font-size: 12px;
            line-height: 20px;
            top: 22%;
        }
    }
    &.top {
        bottom: auto;
        top: 0;
        @include media('max-sm') {
            font-size: 12px;
            line-height: 20px;
            top: 40%;
        }
    }
}

.img-left {
    float: left;
    margin: 0 15px 0px 0;
}
.img-right {
    float: right;
    margin: 0 0 0px 15px;
}

a {
  &.title-red.title-nav {
    font-weight: bold;
  }

  &.text-color {
    color: $text-color;
  }
}

.yamm {
  .yamm-content {
    ul {
      &.nav {
        margin-bottom: 10px;
      }
    }
  }
}

.padding-bottom-0 {
    padding-bottom: 0px;
}

.padding-top-0 {
    padding-top: 0px;
}
