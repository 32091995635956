.single-event {
    article {
        max-width: 768px;
        margin: 0 auto;

        &.content {
            padding-top: 25px;
        }
    }

    .event-icon {
        display: inline-flex;
        width: 50px;
        height: 50px;
        color: white;
        font-size: 30px;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
    }

    .event-date {
        background: #f2f2f2;
        padding: .25rem 1rem;
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
        margin-left: 1rem;
    }

    .meta {
        display: flex;
        align-items: center;
    }

    .featured-image-wrapper {
        .featured-image {
            display: flex;
            align-items: end;
            padding: 1rem;
            height: 500px;
            background-size: cover;
            background-position: center;
            border-radius: 8px;

            &.has-description {
                border-radius: 8px 8px 0 0;
            }

            .event-date {
                background: #ffffff;
            }
        }

        .description {
            background-color: rgb(242, 242, 242);
            color: black;
            width: 100%;
            padding: 0.5rem 1rem 0.5rem 1rem;
            font-size: 14px;
            border-radius: 0 0 8px 8px;
        }
    }
}

event.content {
    h2 {
        font-size: 25px;
        margin-bottom: 10px;
        line-height: 30px;
    }
}

