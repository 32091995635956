body.single-nieuws .website-entrance {
  padding-bottom: 18rem;
}

body.nieuws .website-entrance {
  padding-bottom: 5rem;
}

body.single-projecttypen {
  .website-entrance {
    padding-bottom: 0rem;
  }
  .grid-container .project-item .project-image {
    height: 250px;
  }
}

body.single-nieuws {
  .grid-container .project-item .project-image {
    height: 250px;
  }
}


body.single-projecten .project-single, body.single-nieuws .project-single {
  .website-entrance {
    padding-bottom: 300px;
    @include media(max-lg) {
      padding-bottom: 225px;
    }
    @include media(max-md) {
      padding-bottom: 175px;
    }
  }
}

.featured-image-wrapper .featured-image {
  height: 550px;
  margin-top: -275px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 6rem;
  @include media(max-lg) {
    height: 450px;
    margin-top: -235px;
    margin-bottom: 4rem;
  }
  @include media(max-md) {
    height: 350px;
    margin-top: -185px;
    margin-bottom: 2rem;
  }
}

.meta {
  display: flex;
  font-family: 'ClashGrotesk-Variable';
  font-weight: 400;
  flex-wrap: wrap;
  a.tax {
    background-color: white;
    padding: .5rem 1.5rem;
    border-radius: 25px;
    margin-right: .5rem;
  }
  .date {
    color: white;
    height: 40px;
    line-height: 40px;
  }
}
