.company-search {
    width: 100%;
    background-color: #f2f2f2;
    padding: 1rem 1rem 0 1rem;
    border-radius: 8px 8px 0 0;
    border: none;
}

.search-block {
    border-radius: 8px;

    input.form-control {
        width: 100%;
        padding: 1rem 1.5rem;
        border-radius: 8px;
    }

    button {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    .form-wrap {
        position: relative;
    }
}

.company-list {
    background-color: #f2f2f2;
    padding: 1rem;
    border-radius: 0 0 8px 8px;

    .company-item {
        border-radius: 8px;
        overflow: hidden;
        padding: 1rem 1rem 0.5rem 1rem;

        .company-logo {
            border: 1px solid #d8d8d8;
            margin-right: 1rem;
            border-radius: 8px;
            &.fallback {
                background-size: 400%;
            }
        }

        .company-content {
            a {
                text-decoration: none;
            }
            h3 {
                color: black;
                margin-bottom: 5px;
            }
        }

        .address {
            span {
                font-size: 14px;
                padding-left: 10px;
                margin-bottom: 10px;
                display: inline-block;
            }
        }

        .metadata {
            span {
                border: 1px solid #d8d8d8;
                padding: 5px 10px;
                display: inline-block;
                border-radius: 4px;
                font-size: 13px;
                margin-right: 10px;
                margin-bottom: .5rem;

                a {
                    color: black;
                    margin-left: 5px;
                }

                &:hover {
                    background-color: #f2f2f2;
                }
            }
        }
    }
}


.grid-container {
    padding-bottom: 2rem;
    @include media(max-md) {
        padding-bottom: 1rem;
    }

    .grid-heading {
        h3 {
            margin: 0;
            display: flex;
            align-items: center;
            font-size: 24px;
        }
    }
}

.event-item {
    .event-side-image {
        border-radius: 8px;
    }

    a {
        color: black;
        padding: 1rem;
        display: block;
        border-radius: 8px;
        text-decoration: none;

        &:hover, &:visited {
            color: black;
        }

        .date {
            display: inline-block;
            padding: 0 0.5rem;
            border-radius: 4px;
            margin-right: 0.25rem;
            text-transform: uppercase;
            font-weight: 700;
            color: white;
            font-size: 13px;
            margin-bottom: 0.25rem;
        }
        .widget-heading {
            color: white;
            text-transform: uppercase;
            font-size: 30px;
            line-height: 37px;
            font-weight: 800;
            display: block;
            @include media(md) {
                font-size: 22px;
                line-height: 30px;
                padding-top: 2rem;
            }
            @include media(lg) {
                font-size: 30px;
                line-height: 37px;
            }
            @include media(2xl) {
                font-size: 35px;
                line-height: 42px;
            }
        }
    }
}

.list-container {
    .list-item-container {
        .list-side-image {
            border-radius: 8px;
        }

        a {
            color: black;
            display: block;
            border-bottom: 1px solid #d8d8d8;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            text-decoration: none;

            &:hover, &:visited {
                color: black;
            }
        }
    }
}

.timed-list {
    .block-heading {
        background-color: #f2f2f2;
        width: 100%;
        text-align: center;
        font-size: 13px;
        padding: 0.25rem;
        margin-bottom: 0;
        border-radius: 8px;
    }
    ul.content-timed {
        margin-left: 0;
        margin-bottom: 0;

        .timed-item {
            a {
                padding: 1rem 0;
                border-bottom: 1px solid #d8d8d8;
                display: flex;
                width: 100%;
                text-decoration: none;

                .time {
                    width: 60px;
                    font-size: 13px;
                    padding-left: 10px;
                }

                .meta {
                    width: calc(100% - 60px);
                }
            }
        }
    }
}


.untimed-list {
    .block-heading {
        background-color: #f2f2f2;
        width: 100%;
        text-align: center;
        font-size: 13px;
        padding: 0.25rem;
        margin-bottom: 0;
        border-radius: 8px;
    }
    ul.content {
        margin-left: 0;
        margin-bottom: 0;

        .item {
            a {
                padding: 1rem 0;
                border-bottom: 1px solid #d8d8d8;
                display: flex;
                width: 100%;
                text-decoration: none;
            }
        }
    }
}

.section-border-top {
    border-top: 1px solid #d8d8d8;
    margin-top: 2rem;
    padding-top: 2rem;
}

.related-block {

    .related-title {
        height: 1px;
        background-color: #d8d8d8;
        margin-top: 2rem;
        margin-bottom: 2rem;
        position: relative;

        span {
            position: absolute;
            background-color: white;
            text-transform: uppercase;
            font-weight: bold;
            color: #9a9a9a;
            font-size: 12px;
            border-radius: 50px;
            padding: 3px 1rem;
            margin-top: -16px;
            border: 1px solid #d8d8d8;
        }
    }

    ul.related-items {
        li {
            display: inline-block;

            a {
                display: inline-block;
                padding: 0 0.5rem;
                border-radius: 4px;
                margin-right: 0.25rem;
                text-transform: uppercase;
                text-decoration: none;
                font-weight: 700;
                color: white;
                font-size: 13px;
                margin-bottom: 0.25rem;
            }
        }
    }
}

.project-swiper {
    @include media(max-sm) {
        overflow: hidden;
    }
    .swiper-slide {
        //height: 160px;
        border-radius: 8px;
        background-color:white;
        overflow: hidden;
        background-position: center;
        background-size: cover;
        cursor: pointer;
        @include media(max-sm) {
            //height: 210px;
        }
        @include media(sm) {
            //height: 320px;
        }
        .project-image {
            height: 480px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            @include media(max-md) {
                height: 380px;
            }
            @include media(max-sm) {
                height: 280px;
            }
        }
        .project-date {
            background-color: #EAEAE9;
            height: 34px;
            width: 93px;
            text-align: center;
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 25px;
            font-weight: 400;
            font-size: .875rem;
            position: absolute;
            right: 10px;
            bottom: 10px;
        }
        .plus-icon {
            width: 30px;
            height: 30px;
            background-color: white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}

.heading {
    .swiper-btns {
        display: flex;
        margin-left:2rem;
        margin-top: -.5rem;
        @include media(max-sm) {
            margin-left: 0;
            margin-top: 1rem;
            justify-content: center;
        }
        .swiper-btn-next, .swiper-btn-prev {
            border: .5px solid #babdbf;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #061720;
            width: 48px;
            font-size: 12px;
            height: 48px;
            margin-right: .5rem;
            border-radius: 50%;
        }
    }
}

.grid-container {
    .project-item {
        a {
            background-color: white;
            border-radius: 8px;
            display: flex;
            position: relative;
            overflow: hidden;
            height: 100%;
            flex-direction: column;
        }
        .project-image {
            height: 480px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            @include media(max-md) {
                height: 380px;
            }
            @include media(max-sm) {
                height: 280px;
            }
        }
        .project-date {
            background-color: #EAEAE9;
            height: 34px;
            width: 93px;
            text-align: center;
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 25px;
            font-weight: 400;
            font-size: .875rem;
            position: absolute;
            right: 10px;
            bottom: 10px;
        }
        .plus-icon {
            width: 30px;
            height: 30px;
            background-color: white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
    .in-de-media-item {
        a {
            background-color: white;
            border-radius: 8px;
            display: flex;
            position: relative;
            overflow: hidden;
            height: 100%;
            flex-direction: column;
        }
        .in-de-media-image {
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            height: 260px;
            position: relative;
            border: 15px solid white;
        }
        .in-de-media-date {
            background-color: #EAEAE9;
            height: 34px;
            width: 93px;
            text-align: center;
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 25px;
            font-weight: 400;
            font-size: .875rem;
            position: absolute;
            right: 10px;
            bottom: 10px;
        }
        .plus-icon {
            width: 30px;
            height: 30px;
            background-color: white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}

.grid-container {
    &.grid-certificates {
        max-width: 980px;
    }
    .certificate-item {
        a {
          color: #f6923b;
          font-weight: 500;
          font-family: 'ClashGrotesk-Variable';
            &:hover {
                text-decoration: underline;
            }
        }
        .certificate-image {
            height: 200px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }
}

body section.bg-darkblue .grid-container .certificate-item a {
    color: #f6923b;
}

.bg-gray .bg-gray {
    background-color: white;
}

.two-col {
    h3 {
        font-size: 30px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 2rem;
    }
    @include media(max-lg) {
        .image-block {
            height: 300px;
        }
    }
}

.intro ul {
    list-style: unset;
    margin: 1rem;
    padding-left: .5rem;
}

.assortiment .buttons span, .assortiment .buttons h2 {
    color: black !important;
}

.integrated-block ul {
    list-style: unset;
    margin: 1rem;
    padding-left: .5rem;
}
