article.heading.blog {
    display: flex;
    .featured-image-wrapper {
        margin-right: -250px;
        margin-left: 2rem;
        @include media('max-2xl') {
            margin-right: -70px;
            margin-left: 2rem;
        }
        @include media('max-xl') {
            margin-right: 0;
            margin-left: 1rem;
        }

        .featured-image {
            width: 250px;
            height: 250px;
            border-radius: 100%;
            @include media('max-2xl') {
                width: 200px;
                height: 200px;
            }
            @include media('max-xl') {
                width: 175px;
                height: 175px;
            }
            @include media('max-md') {
                width: 125px;
                height: 125px;
            }
        }
    }
}
