/* POPAP */
.modal-content {
  border-radius: 0px;
  border: none;
}

.modal-dialog {
  @include media('max-sm') {
    margin-top: 50px;
  }
}

.modal-title {
  @extend .h3;
}

.modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
  .close {
    margin-top: 3px;
    text-shadow: none;
    opacity: 1;
    color: $color-dove-gray;
    font-size: 25px;
    &:hover {
      color: $color-tamarillo;
    }
  }
}

.modal-body {
  padding-top: 25px;
  padding-bottom: 25px;
}

.modal-backdrop {
  &.in {
    opacity: .85;
  }
  z-index: 0;
}

/* Login modal */
#login-modal {
  .modal-dialog {
    width: 370px;
    @include media('max-sm') {
      width: auto;
    }
  }
  .modal-header {
    background: $color-mine-shaft;
  }
  .modal-title {
    color: $color-white;
  }
}
