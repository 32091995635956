/* DROPDOWN */
.dropdown {
	>a {
		>i {
			font-size: 18px;
			vertical-align: bottom;
		}
	}
	&.open {
		>a {
			background: $color-black;
			color: $color-white;
		}
		.dropdown {
			&:hover, &:focus {
				>a {
					background: $color-concrete;
					color: $color-tamarillo;
				}
			}
		}
	}
}
.dropdown-menu {
    min-width: 180px;
    border-radius: 0;
    top: 57px;
    border: none;
    background-color: #f2f2f2;
    padding: 1rem;
    margin-bottom: 0;
	@include media('max-md') {
		margin-top: 0;
		min-width: 100%;
		width: 100%;
	}
}
.dropdown-submenu {
	position:relative !important;
	>.dropdown-menu {
		top:0;
		left:100%;
		right: -100% !important;
		margin-top:-6px;
		margin-left:-1px;
		@include media('max-md') {
			margin-top: 34px;
			left: 0;
		}
	}
}
.integrated-website-menu-list .dropdown:hover .dropdown-menu {
	display: block;
}
.navbar-right {
	.dropdown-menu {
		right: auto;
		&.dropdown-menu-right {
			right: 0;
		}
	}
}

/* Maga menu stiles */
.yamm .nav,
.yamm .collapse,
.yamm .dropup,
.yamm .dropdown {
  	position: static;
  	@include media('max-md') {
  		position: relative;
  	}
}
.yamm .container {
  	position: relative;
}
.yamm .dropdown-menu {
  	left: auto;
  	@include media('max-md') {
		left: 0;
	}
}
.yamm .yamm-content {
  	padding: 20px 30px;
  	@include media('max-md') {
  		padding: 10px 15px;
  	}
}
.yamm .dropdown.yamm-fw .dropdown-menu {
  	left: 0;
  	right: 0;
}
