.company {
    &.backdrop {
        margin-bottom: 300px;
        margin-top: -1px;
    }
    .company-info {
        margin-bottom: -315px;
    }
    .video {
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        cursor:pointer;
        overflow: hidden;
        .playbutton {
            width: 50px;
            height: 50px;
            background-size: contain;
            border-radius: 100%;
        }
    }
}
