.image-block, .featured-image-wrapper {
  .intro {
    max-width: 768px;
    margin: 0 auto;
    font-weight: 400;
  }

  .buttons {
    display: flex;
    justify-content: center;

    a {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .two-col {
    margin-top: -2rem;

    .buttons {
      justify-content: start;
    }
  }

  .background-image-overlay {
    border-radius: 25px;
  }

  &.entry-image {
    height: 100vh;
    @include media(max-lg) {
      height: 100vh;
      height: 100svh;
    }
    background-position: center;

    h2 {
      font-size: 5rem;
      max-width: 460px;
      line-height: 5rem;
      margin-bottom: 0rem;
      @include media(max-lg) {
        font-size: 4rem;
        line-height: 4rem;
        max-width: 420px;
      }
      @include media(max-md) {
        font-size: 3rem;
        line-height: 3rem;
        max-width: 320px;
      }
    }

    .buttons {
      display: flex;
      justify-content: start;
    }
  }
}

.de_wilde_sierbestrating_website {
  .image-block.entry-image h2 {
    max-width: 630px;
  }
}

.h-full .image-block {
  height: 100%;
  border-radius: 8px;
}

.background-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  background-position: bottom left;
}

.rounded-10 {
  border-radius: 8px;
}
