/* MARQUEE */
#news-holder {

}
.news-holder-inner {
	background: $color-alabaster;
	white-space: nowrap;
	overflow: hidden;
	.label {
		margin-right: 20px;
	}
}

.marquee {
	margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
    -webkit-animation: marquee 50s linear infinite;
	animation: marquee 50s linear infinite;
	&:hover {
		animation-play-state: paused
	}
	a {
		position: relative;
		display: inline;
		padding: 0 15px;
		color: $color-mine-shaft;
		line-height: 36px;
		@include media('max-md') {
			line-height: 24px;
		}
		&:hover {
			color: $color-tamarillo;
		}
		&:before {
			content: '';
			height: 4px;
			width: 4px;
			display: block;
			border-radius: 50%;
			background-color: $color-mine-shaft;
			position: absolute;
			top: 50%;
			right: -3.5px;
			margin-top: -2px;
		}
		&:last-child {
			&:before {
				content: none;
			}
		}
	}
}


@include keyframes(marquee) {
  	from {
    	text-indent: 27.5em;
  	}
  	to {
    	text-indent: -105em;
  	}
}


