body.projecten {
  .website-entrance {
    padding-bottom: 0rem;
  }
}

body.missie.visie {
  .website-entrance {
    padding-bottom: 0rem;
  }
}

.reimert-heading h1 {
  font-size: 60px;
  font-weight: 500;
  line-height: 60px;
  margin-bottom: 1.5rem;
  padding-top: 5rem;
}

.projects-cta .container {
  background-position: 0% -57%;
  background-repeat: no-repeat;
  background-size: cover;
}

.sharebuttons a {
  color: black !important;
  text-decoration: none !important;
}
