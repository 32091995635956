/* MIXINS */
// Different devices resolutions
@mixin media($size) {
    @if ($size == "sm") {
        @media only screen and (min-width: 640px) {
            @content;
        }
    } @else if ($size == "max-sm") {
        @media only screen and (max-width: 640px) {
            @content;
        }
    } @else if ($size == "max-md") {
        @media only screen and (max-width: 768px) {
            @content;
        }
    } @else if ($size == "md") {
        @media only screen and (min-width: 768px) {
            @content;
        }
    } @else if ($size == "max-lg") {
        @media only screen and (max-width: 1024px) {
            @content;
        }
    } @else if ($size == "lg") {
        @media only screen and (min-width: 1024px) {
            @content;
        }
    } @else if ($size == "max-xl") {
        @media only screen and (max-width: 1280px) {
            @content;
        }
    } @else if ($size == "xl") {
        @media only screen and (min-width: 1280px) {
            @content;
        }
    } @else if ($size == "max-2xl") {
        @media only screen and (max-width: 1440px) {
            @content;
        }
    } @else if ($size == "2xl") {
        @media only screen and (min-width: 1440px) {
            @content;
        }
    } @else if ($size == "3xl") {
        @media only screen and (min-width: 1536px) {
            @content;
        }
    } @else if ($size == "4xl") {
        @media only screen and (min-width: 1768px) {
            @content;
        }
    } @else if ($size == "5xl") {
        @media only screen and (min-width: 1921px) {
            @content;
        }
    }
}


@mixin transition($transition...) {
    -webkit-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
@mixin transition-property($transition-property...) {
    -webkit-transition-property: $transition-property;
    transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
    -webkit-transition-delay: $transition-delay;
    transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
    -webkit-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
    -webkit-transition-timing-function: $timing-function;
    transition-timing-function: $timing-function;
}
@mixin transition-transform($transition...) {
    -webkit-transition: -webkit-transform $transition;
    -moz-transition: -moz-transform $transition;
    -o-transition: -o-transform $transition;
    transition: transform $transition;
}

@mixin clearfix {
    &::after {
        clear: both;
        content: "";
        display: table;
    }
}


// Transformations
@mixin scale($ratio...) {
    -webkit-transform: scale($ratio);
    -ms-transform: scale($ratio); // IE9 only
    -o-transform: scale($ratio);
    transform: scale($ratio);
}

@mixin scaleX($ratio) {
    -webkit-transform: scaleX($ratio);
    -ms-transform: scaleX($ratio); // IE9 only
    -o-transform: scaleX($ratio);
    transform: scaleX($ratio);
}
@mixin scaleY($ratio) {
    -webkit-transform: scaleY($ratio);
    -ms-transform: scaleY($ratio); // IE9 only
    -o-transform: scaleY($ratio);
    transform: scaleY($ratio);
}
@mixin skew($x, $y) {
    -webkit-transform: skewX($x) skewY($y);
    -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
    -o-transform: skewX($x) skewY($y);
    transform: skewX($x) skewY($y);
}
@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y); // IE9 only
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}
@mixin translate3d($x, $y, $z) {
    -webkit-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}
@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees); // IE9 only
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}
@mixin rotateX($degrees) {
    -webkit-transform: rotateX($degrees);
    -ms-transform: rotateX($degrees); // IE9 only
    -o-transform: rotateX($degrees);
    transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
    -webkit-transform: rotateY($degrees);
    -ms-transform: rotateY($degrees); // IE9 only
    -o-transform: rotateY($degrees);
    transform: rotateY($degrees);
}
@mixin perspective($perspective) {
    -webkit-perspective: $perspective;
    -moz-perspective: $perspective;
    perspective: $perspective;
}
@mixin perspective-origin($perspective) {
    -webkit-perspective-origin: $perspective;
    -moz-perspective-origin: $perspective;
    perspective-origin: $perspective;
}
@mixin transform-origin($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin; // IE9 only
    transform-origin: $origin;
}

// Placeholder text
@mixin custom-placeholder($color: $input-color-placeholder, $font-size: $input-font-size-placeholder, $line-height: $input-line-height-placeholder) {
    &:-moz-placeholder {
        color: $color;
        font-size: 14px;
        line-height: $line-height;
        opacity: 1;
        @include media('max-sm') {
            font-size: 10px;
        }
    }
    &::-moz-placeholder {
        color: $color;
        font-size: 14px;
        line-height: $line-height;
        opacity: 1;
        @include media('max-sm') {
            font-size: 10px;
        }
    }
    &:-ms-input-placeholder {
        color: $color;
        font-size: 14px;
        line-height: $line-height;
        opacity: 1;
        @include media('max-sm') {
            font-size: 10px;
        }
    }
    &::-webkit-input-placeholder {
        color: $color;
        font-size: 14px;
        line-height: $line-height;
        opacity: 1;
        @include media('max-sm') {
            font-size: 10px;
        }
    }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@mixin prefix($property, $value, $vendors: webkit moz ms o khtml, $default: true) {
    @if $vendors {
        @each $vendor in $vendors {
            #{"-" + $vendor + "-" + $property}: #{$value};
        }
    }
    @if $default {
        #{$property}: #{$value};
    }
}

@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

@mixin opacity($value) {
    $IEValue: $value*100;
    opacity: $value;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
    filter: alpha(opacity=$IEValue);
}

@mixin font-size($sizeValue: 1) {
    font-size: ($sizeValue * 14) * 1px;
    font-size: $sizeValue * 1rem;
}

@import "flex-mixins";
