.article {
    .meta-item {
        margin-bottom:1rem;
    }
}

.meta-item {
    align-items: center;
    border: 1px solid white;
    border-radius: 25px;
    color: #000;
    display: inline-flex;
    font-size: 14px;
    justify-content: center;
    padding: .25rem .75rem;
    position: relative;
    width: 100px;
    height: 40px;
    background-color: white;
    @include media(max-sm) {
        min-height: 30px;
        padding: 0rem 0.5rem;
    }

    svg {
        height: 18px;
        width: 18px;
        @include media(max-sm) {
            height: 15px;
            width: 15px;
        }
    }

    &.socialshare {
        cursor: pointer;

        .socialshare-button {
            box-sizing: border-box;
            display: flex;
            align-items: center;

            * {
                pointer-events: none;
            }
            i {
                margin-left: 0.5rem;
            }
        }

        .sharebuttons {
            position: absolute;
            top: -50px;
            left: 0px;
            border: 1px solid #d8d8d8;
            background-color: white;
            height: 40px;
            align-items: center;
            padding: 0 10px;
            border-radius: 50px;
            display: none;
            z-index: 9;
            pointer-events: all;

            &.show {
                display: flex;
            }

            a {
                color: black;
                padding: 10px 10px;
                display: inherit;
                text-decoration:none;
                pointer-events: all;
            }
        }
    }

}
