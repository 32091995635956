.article {
  img {
    border-radius: 8px;
  }

  h2, h3, h4, h5, h6 {
    color: white;
  }

  article {
    max-width: 768px;
    margin: 0;

    &.content {
      padding-top: 25px;
    }

    a {
      text-decoration: underline;

      &:hover, &:focus {
        text-decoration: underline;
      }

    }

  }

  article ul {
    list-style: unset;
    margin: 1rem;
    padding: 0 0 0 .5rem;
  }

  .featured-image-wrapper {
    .featured-image {
      height: 500px;
      @include media(max-xl) {
        height: 400px;
      }
      @include media(max-lg) {
        height: 350px;
      }
      @include media(max-md) {
        height: 300px;
      }
      @include media(max-sm) {
        height: 250px;
      }
      background-size: cover;
      background-position: center;
      border-radius: 25px;

      &.has-description {
        border-radius: 8px 8px 0 0;
      }
    }

    .description {
      background-color: rgb(242, 242, 242);
      color: black;
      width: 100%;
      padding: 0.5rem 1rem 0.5rem 1rem;
      font-size: 14px;
      border-radius: 0 0 8px 8px;
    }
  }
}

article.content {
  h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 30px;
  }

  font-family: 'Helvetica Neue LT Std';

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
  }
}

article .intro {
  margin-bottom:1rem;
  font-weight: 500;
}

article ul {
  list-style: unset;
  margin: 1rem;
  padding: 0 0 0 .5rem;
}

article.heading h1 {
  display: flex;
  align-items: center;
  max-width: 768px;
  font-size: 65px;
  font-weight: 500;
  color: white;
  line-height: 65px;
  margin-bottom: 3rem;
  @include media(max-lg) {
    font-size: 50px;
    line-height: 55px;
  }
  @include media(max-md) {
    font-size: 35px;
    line-height: 40px;
  }

  .socialshare {
    margin-left: 1rem;
  }
}

.content-image {
  position: relative;
}

figure {
  margin: 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin: 1rem 0;

  figcaption {
    &.caption {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      color: white;
      padding: 5rem 1rem 1rem;
      background: linear-gradient(to top, hsla(230, 40%, 5%, 0.95), hsla(230, 40%, 5%, 0));
    }

    &.credit {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #f2f2f2;
      font-size: 13px;
      color: #6e6e6e;
      padding: 0.25rem 0.5rem;
      border-bottom-left-radius: 8px;
    }
  }

  img {
    border-radius: 8px;
  }
}

span.img-credit {
  margin-left: 5px;
  color: rgb(102, 102, 102);
}

span.img-caption {
  align-items: center;
  padding-top: 0.5rem;
  color: black;
  font-weight: 500;
  font-size: 0.75rem;
}

.glightbox {
  cursor: pointer;
}

body {
  .article-swiper {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .slider-image {
    height: 400px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
  }
}

.share {
  border-top: 2px solid #eaeaea;
  margin-top: 3rem;
  padding-top: 2rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-family: 'ClashGrotesk-Variable';
  .sharebuttons {
    display: flex;
    .sharebutton {
      border: 1px solid black;
      width: 40px;
      height: 40px;
      display: flex;
      border-radius: 100%;
      justify-content: center;
      align-items: center;
      margin-left: .5rem;
    }
  }
}

.content p:last-child {
  margin-bottom: 0;
}

.bg-blue a:not(.btn):not(.tax) {
  color: white;
  text-decoration: underline;
}
