body.contact .website-entrance {
  padding-bottom: 6rem;
  @include media(max-lg) {
    padding-bottom: 4rem;
  }
  @include media(max-md) {
    padding-bottom: 2rem;
  }
}

.map-info {
  @include media(lg) {
    padding-left: 2rem;
  }
  @include media(xl) {
    padding-left: 30px;
  }

  .image-block {
    height: 440px;
    border-radius: 8px;
    margin-top: 2rem;
    @include media(md) {
      height: 800px;
    }
  }
}

.form-title-holder h2 {
  display: block;
}

.contact {
  .form-title-holder h2 {
    font-size: 25px;
    line-height: 40px;
    font-family: 'ClashGrotesk-Variable', sans-serif;
    @include media(md) {
      font-size: 35px;
      line-height: 70px;
    }
  }

  .contact-block {
    .socials {
      margin-top: 1rem;

      a {
        width: 30px;
        height: 30px;
        color: black;
        border: 1px solid black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
      }
    }
  }
}

.contact-block {
  h2 {
    font-size: 40px;
  }

  .heading h3 {
    font-size: 30px;
    text-align: left;
    margin-top: 1rem;
  }
}

.contact-address {
  color: black;

  .address-complete {
    font-style: normal;
  }
}

.contact-phone a {
  color: black;
  font-style: normal;
}

