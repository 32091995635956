/* MEDIA */
.media {
	p {
		margin-bottom: 5px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.media-red-title {
	color: $color-tamarillo;
	font-size: 16px;
	line-height: 25px;
	@include media('max-sm') {
		font-size: 13px;
		line-height: 18px;
	}
}
.gap-left {
  margin-left: 10px;
}
.gap-right {
    margin-right: 10px;
}
