.pagecount {
    margin: 0 auto;
    display: inline-block;
    background-color: #f2f2f2;
    padding: .5rem 1rem;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
}

.sort-by-holder {
    display: flex;
    padding: .5rem;
}
