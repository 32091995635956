.alert-inline {
    position: relative;
    bottom: 0;
    left: 0;
    background-color: white;
    border-radius: 1rem;
    box-shadow: none;
    margin-bottom: 1rem;
}

.alert {
    position: relative;
    width: 100%;
    min-width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 14px;
    right: auto;
    display: block;
    bottom: auto;
    margin-bottom: 0.5rem;
    box-shadow: 0 0 0 0;
}

.close {
    cursor: pointer;
    font-size: 1.6rem;
    height: 20px;
    opacity: 1;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 15px;
    width: 20px;
    position: absolute;
}

@mixin alert($name,$bgColor) {
    $accentColor: darken($bgColor, 50);
    .#{$name} {
        background-color: white;
        border: 1px solid $accentColor;
        border-left: 5px solid $accentColor;

        &:empty {
            display: none;
        }
    }
}

@include alert(alert-simple, #ebebeb);
@include alert(alert-success, #a8f0c6);
@include alert(alert-danger, #f7a7a3);
@include alert(alert-warning, #ffd48a);
