
.bg-whiteblue {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgb(49, 153, 211) 50%);
  @include media(max-lg) {
    background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgb(49, 153, 211) 50%);
  }
  @include media(max-sm) {
    background: white;
  }
}

body.de_wilde_sierbestrating_website .bg-whiteblue {
  @include media(max-sm) {
    background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgb(49, 153, 211) 50%);
  }
}

section.bg-darkblue {
  background-color:#061720;
  a:not(.btn) {
    color:white;
    text-decoration: underline;
    &:hover {
      color: #3199D3;
    }
  }
}

section.bg-blue {
  background-color:#3199D3;
}

.bg-gray {
  background-repeat: no-repeat;
  background-color: #EAEAEA;
  background-position: bottom left;
}

.frontpage-entry-content  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 2.5rem;
    @include media(max-xl) {
      font-size: 1.75rem;
      line-height: 2rem;
    }
    @include media(max-lg) {

    }
    @include media(max-md) {

    }
  }
}
.heading {

}

.subheading {
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

.heading h3 {
  font-size: 42px;
  font-weight: 400;
  text-align: center;
}

.frontpage-diensten {
  max-width: 1096px;
  .image-block {
    height: 100%;
    border-radius: 8px;
  }
  a.btn {
    margin-right: 1rem;
  }
}


.frontpage-certificaten {
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-family: 'ClashGrotesk-Variable';
  color:white;
  @include media(max-lg) {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}

section.certificates {
  background-repeat: no-repeat;
  background-position: 83% 83%;
}

.werken-bij-text {
  max-width: 768px;
  color: white;
  font-weight: 400;
  h3 {
    font-size: 42px;
    font-weight: 400;
    line-height: 42px;
    margin-bottom: 1.5rem;
  }
}

.partnership-block {
  border-radius: 8px;
  overflow: hidden;

  .partnership-image {
    width: 150px;
    height: 50px;
    background-size: contain;
    border-radius: 8px;
    border: 5px solid white;
    background-repeat: no-repeat;
  }
}

.brands {
  border-top: 1px solid #CFD1D2;
  border-bottom: 1px solid #CFD1D2;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 3rem;
  .brandlogo {
    //max-width: 165px;
    .logo {
      height: 52px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

body.de_wilde_sierbestrating_website .frontpage-diensten .image-block {
  min-height: 300px;
}
