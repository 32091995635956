.widget {
  .video {
    height: 188px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;

    .playbutton {
      width: 50px;
      height: 50px;
      background-size: contain;
      border-radius: 100%;
    }
  }
}

.video {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;

  .playbutton {
    width: 50px;
    height: 50px;
    background-size: contain;
    border-radius: 100%;
  }
}

.missie-visie {
  .video {
    height: 300px;
    @include media(md) {
      height: 400px;
    }
  }
}

body {
  &.over.reimert, &.over.ubink, &.over.dewilde, &.over.beugel {
    .video-background {
      height: 520px;
      @include media(max-xl) {
        height: 362px;
      }
      @include media(max-lg) {
        height: 267px;
      }
    }
  }
}

.video-foreground {
  width: 100%;
  border-radius: 8px;
}
