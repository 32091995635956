body {
    .select2-container .select2-search--inline .select2-search__field {
        height: 27px;
        border: none !important;
        padding: 0 0.5rem !important;
        margin-left: 0;
    }
    .select2-container--default.select2-container--focus .select2-selection--multiple {
        border: 1px solid #aaa;
    }
    .select2-dropdown {
        font-size: 14px;
    }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
        font-size: 14px;
    }
    .select2-container {
        max-width: 100%;
    }

    .select2-container .select2-selection--multiple .select2-selection__rendered {
        display: inline;
        list-style: none;
        padding: 0;
        margin-left: 0;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        top: -5px;
    }

    .select2-container--default .select2-selection--multiple {
        background-color: white;
        border: none;
        border-radius: 4px;
        cursor: text;
        padding: 0;
        position: relative;
    }

    .select2-container--default .select2-selection--multiple {
        cursor: text;
        position: relative;
        width: 100%;
        padding: 0.5rem 0;
        border-radius: 4px;
        border: 1px solid #d8d8d8;
        font-size: 14px;
        background-color: white;
    }

    .select2-container .select2-selection--single {
        box-sizing: border-box;
        display: block;
        -webkit-user-select: none;
        cursor: pointer;
        position: relative;
        height: auto;
        width: 100%;
        padding: 0.5rem 0;
        border: 1px solid #d8d8d8 !important;
        font-size: 14px;
        background-color: white;
    }
    .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
        border-color: #aaaaaa !important;
    }
}
