button {
    &:focus {
        outline: none;
    }
}

.btn-l, .btn-m, .btn-s {
    @include prefix(transition, 0.3s, webkit moz o);

    //i, svg, rect {
    //    @include prefix(transition, 0.3s, webkit moz o);
    //}
}

.btn {
    font-family:'ClashGrotesk-Variable';
    font-weight: 500;
    border: 1px solid transparent;
    display: inline-flex;
    align-items: center;
    border-radius: $border-radius;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    line-height: normal;
    cursor: pointer;
    text-decoration: none !important;
    .icon {
        display: flex;
    }
    &.btn-l {
        padding: 0.4rem 0.8rem;
        font-size: 1rem;

        &:focus {
            outline: none;
        }

        &.space-between {
            display: flex;
            justify-content: space-between;
        }

        @include media(md) {
            padding: 0.5rem .75rem;
        }
        @include media(lg) {
            padding: .688rem 1.5rem;
        }

        &:hover {
            text-decoration: none;
        }

        &.svg {
            padding-left: 0.5rem;
        }

        span {
            line-height: 1.5;
            margin-right: 1rem;
        }

        &.icon-left {
            span {
                margin-left: 1rem;
                margin-right: 0;
            }
        }

        &.no-icon {
            span {
                margin-right: 0;
            }
        }

        svg {
            display: inline-block;
        }
    }

    &.btn-m {
        padding: 0.25rem 1rem;
        font-size: 0.825rem;
        line-height: 1.25rem;

        @include media(md) {
            font-size: 1rem;
            line-height: 1.7rem;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            text-decoration: none;
        }

        span {
            margin-top: -0.25rem;
        }

        svg {
            max-height: 17px;
            width: 17px;
            margin-right: 6px;
            display: inline-block;
        }
    }

    &.btn-s {
        padding: 0.25rem;
        font-size: 0.7rem;
        line-height: 1;

        @include media(md) {
            padding: 0.5rem;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            text-decoration: none;
        }

        span {
            margin-top: -0.25rem;
        }

        svg {
            max-height: 14px;
            width: 14px;
            margin-right: 6px;
            display: inline-block;
        }
    }
    &.btn-bordered-white {
        color:white;
        background-color:transparent;
        border: 1px solid rgba(255, 255, 255, 0.5);
        &:hover{
            color:white;
            background-color:rgba(0,0,0,0.3);
        }
    }

    &.btn-bordered-black {
        color:black;
        background-color:transparent;
        border:1px solid black;
        &:hover{
            color:black;
            background-color:rgba(0,0,0,0.1);
        }
    }

    &.btn-white {
        color:black;
        background-color:white;
        border:1px solid white;
        &:hover{
            color:black;
            background-color:#FFF;
        }
    }

    &.btn-white-bordered {
        color:white;
        background-color:transparent;
        border:1px solid white;
        &:hover{
            color:white;
            border-color:#F6923B;
            background-color:#F6923B;
        }
    }

    &.btn-gray-bordered {
        color:black;
        background-color:transparent;
        border:1px solid #B2B2B2;
        &:hover{
            color:white;
            border-color:#F6923B;
            background-color:#F6923B;
        }
    }

    &.btn-black {
        color:white;
        background-color:black;
        border:1px solid black;
        &:hover{
            color:white;
            background-color:black;
        }
    }
    &.btn-gray {
        color: #000;
        background-color: #f2f2f2;
        border: 1px solid #f2f2f2;
        &:hover {
            border-color: #000000;
        }
    }

}

.svg-icon {
    width: 21px;
    height: 21px;
}

.toggle-data-target * {
    pointer-events: none;
}
