.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
    position:relative;
}

@include media('sm'){
    .container {
        max-width: 640px
    }
}

@include media('md'){
    .container {
        max-width: 768px
    }
}

@include media('lg') {
    .container {
        max-width: 1024px
    }
}

@include media('xl') {
    .container {
        max-width: 1280px
    }
}

@include media('2xl') {
    .container {
        max-width: 1440px
    }
}

.max-w-768 {
    max-width: 768px;
}
