.pagination-wrapper {
    display: flex;
    justify-content: center;
}
.pagination {
    border-radius: 8px;
    background-color: white;
    text-align: center;
    padding: 1rem;

    &:empty {
        display: none;
    }

    span {
        background-color: #ecf6ff;
        color: white;
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 6px;
        margin: 0 2px;

        a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
        }

        &.current {
            background-color: #f2f2f2;
            color: black;
        }

        &.disabled {
            background-color: #dee3e9;
            color: #404040;
        }

        &:hover {
            a {
                color: white;
            }
        }
    }

    @include media(max-sm) {
        background-color: white;
        border-radius: 8px;
    }
}
