@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables/variables';

@font-face {
  font-family: 'ClashGrotesk-Variable';
  src: url($font-path + 'ClashGrotesk-Variable.woff2') format('woff2'),
  url($font-path + 'ClashGrotesk-Variable.woff') format('woff'),
  url($font-path + 'ClashGrotesk-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: url($font-path + 'HelveticaNeueLTStd-Lt.woff2') format('woff2'),
  url($font-path + 'HelveticaNeueLTStd-Lt.woff') format('woff'),
  url($font-path + 'HelveticaNeueLTStd-Lt.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: url($font-path + 'HelveticaNeueLTStd-Roman.woff2') format('woff2'),
  url($font-path + 'HelveticaNeueLTStd-Roman.woff') format('woff'),
  url($font-path + 'HelveticaNeueLTStd-Roman.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: url($font-path + 'HelveticaNeueLTStd-Md.woff2') format('woff2'),
  url($font-path + 'HelveticaNeueLTStd-Md.woff') format('woff'),
  url($font-path + 'HelveticaNeueLTStd-Md.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: url($font-path + 'HelveticaNeueLTStd-Bd.woff2') format('woff2'),
  url($font-path + 'HelveticaNeueLTStd-Bd.woff') format('woff'),
  url($font-path + 'HelveticaNeueLTStd-Bd.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@import 'lib/mixins';
@import 'global/typography';
@import 'components/reset';
@import 'components/list';
@import 'components/popap';
@import 'components/dropdown';
@import 'components/label';
//@import 'components/owl-carousel';
//@import 'components/top-stories';
@import 'components/thumbnail';
@import 'components/panel';
//@import 'components/breadcrumb';
@import 'components/banner';
@import 'components/marquee';
@import 'components/lightbox';
@import 'components/media';
@import 'components/integrated';
@import 'components/underlay';
@import 'components/slideout';
//@import 'components/ads';
@import 'components/meta';
@import 'components/widget';
@import 'components/pagination';
@import 'components/table';
@import 'components/search';
//@import 'components/paywall';
@import 'components/login';
@import 'components/alert';
@import 'components/select2';
@import 'components/lightbox';
@import 'components/sidebar';
@import 'forms/forms';
@import 'forms/buttons';
@import 'content/content';
@import 'global/container';
@import 'global/header';
@import 'global/footer';
@import 'blocks/block';
//@import 'blocks/subscriptions';
@import 'blocks/content-blocks';
@import 'blocks/video';
//@import 'blocks/testimonials';
@import 'blocks/image';
//@import 'blocks/case-profile';
@import 'pages/frontpage';
@import 'pages/certificates';
@import 'pages/single-project';
@import 'pages/single-nieuws';
@import 'pages/single-jobPosting';
@import 'pages/contact';
@import 'pages/projecten';
@import 'pages/about-us';
@import 'pages/sierbestrating';
@import 'styles';
