/* BANNER */
.banner-holder {
	* {
		line-height: 18px;
	}
	a {
		@extend .underline;
	}
	p {
		text-transform: uppercase;
		font-size: 10px;
	}
	.media-object {
		border: 1px solid $color-mercury;
	}
}