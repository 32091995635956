/* TYPOGRAPHY */
html, body {
	font-family: 'Helvetica Neue LT Std';
	font-size: $body-font-size;
	line-height: $body-line-height;
	font-weight: $font-regular;
	color: $main-text-color;
	-webkit-font-smoothing: antialiased;
	height: 100%;
	min-height: 100%;
}

html {
	overflow-x: hidden;
}

* {
	&:hover, &:focus {
		outline: none;
	}
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $body-tight-font;
	font-weight: $font-regular;
	margin-top: 0;
	line-height: 18px;
	a {
		color: $color-tamarillo;
		&:hover, &:focus {
			color: $color-lochmara;
		}
	}
}

h1, .h1 {
    font-size: 40px;
    margin-bottom: 20px;
    line-height: 40px;
    color: black;
    @include media('max-md') {
    	font-size: 35px;
    	line-height: 35px;
    }
    @include media('max-sm') {
    	font-size: 28px;
    	line-height: 28px;
    }
}

h2, .h2 {
    font-size: 42px;
    margin-bottom: 20px;
    line-height: 42px;
	@include media('max-sm') {
		font-size: 30px;
	}
}

h3, .h3 {
	font-size: 25px;
	margin-bottom: 15px;
	line-height: 28px;
}

h4, .h4 {
	font-size: 20px;
	line-height: 25px;
}

h5, .h5 {
	font-size: 16px;
	line-height: 20px;
}
h6, .h6 {
	font-size: 10px;
	line-height: 18px;
}
small, .small {
	font-size: 10px;
	line-height: 20px;
	text-transform: uppercase;
  &.mobile-small {
    @include media('max-sm') {
      font-size: 10px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
}


.title-red {
	color: $color-tamarillo;
	&.title-nav {
		padding: 0 5px;
	}
}

.font-light {
	font-weight: $font-light;
}
.font-regular {
	font-weight: $font-regular;
}
.font-medium {
	font-weight: $font-medium;
}
.font-bold {
	font-weight: $font-bold;
}

small {
	font-size: 0.7rem;
}
.large {
	font-size: 15px;
	line-height: 25px;
}
a {
	color: black;
	@include transition(150ms);
	&:hover, &:focus {
        color: #3199d3;
	}
	&.underline {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	&.active {
		color: black;
	}
}
button {
	@include transition(150ms);
	border: none;
	background: none;
}
p {
	margin-bottom: 20px;
}
.uppercase {
	text-transform: uppercase;
}
.title-half-line {
	&:after {
		display: block;
		content: '';
		height: 1px;
		width: 60%;
		background: $color-mine-shaft;
	}
}
.title-line {
	&:after {
		display: block;
		content: '';
		height: 1px;
		width: 100%;
		background: $color-mine-shaft;
	}
}


.facebook-bg {
	background: $color-chambray;
	&:hover, &:focus {
		background: darken($color-chambray, 10%);
	}
}
.tweeter-bg {
	background: $color-picton-blue;
	&:hover, &:focus {
		background: darken($color-picton-blue, 10%);
	}
}
.google-bg {
	background: $color-orange-roughy;
	&:hover, &:focus {
		background: darken($color-orange-roughy, 10%);
	}
}
.instagram-bg {
	background: $color-leather;
	&:hover, &:focus {
		background: darken($color-leather, 10%);
	}
}
.youtube-bg {
	background: $color-thunderbird;
	&:hover, &:focus {
		background: darken($color-thunderbird, 10%);
	}
}

.color-white, .color-white:hover {
	color: $color-white;
}
.color-red {
	color: $color-tamarillo;
}
.color-orange {
	color: $color-carrot-orange;
}
.color-gray {
	color: $color-dusty-gray;
}
.color-black, .color-black:hover {
	color: $color-mine-shaft;
}

.wrapper {
	padding: 20px;
	@include media('max-md') {
		padding: 20px 15px;
	}
}
.gray-bg {
	background: $color-alabaster;
}

.frame-intro, .frame-lead, .lead, .frame-subheadline, .subheadline {
	margin-bottom: 20px;
	font-size: 20px;
}

.kader, .frame-general, .frame-streamer, .streamer {
    padding: 1rem;
    background-color: #f2f2f2;
    margin-bottom: 20px;
    border-radius: 8px;
    p {
        &:last-child {
            margin-bottom:0;
        }
    }
}

.frame-streamer, .streamer {
	font-style: italic;
	font-size: 20px;
}

.frame-general p:last-child, .frame-streamer p:last-child {
	margin-bottom: 0px;
}

.frame-embedded-article {
	padding: 20px 20px 20px 23px;
	background-color: #008CB5;
	margin-bottom: 20px;
	color: #ffffff;
}

.frame-embedded-article p:last-child {
	margin-bottom: 0px;
}

.main ul {
    list-style: disc;
    margin-left: 2rem;
    margin-bottom: 1rem;
}

.main a {
    text-decoration:underline;
}

.m-768 {
	max-width: 768px;
	margin: 0 auto;
}

.m-1040 {
	max-width: 1040px;
	margin: 0 auto;
}

b, strong {
	font-weight: 600;
}
