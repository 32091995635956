#dropdown_underlay {
    visibility: visible;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 10;

    &.hide {
        visibility: hidden;
        opacity: 0;
    }
}
