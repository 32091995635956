$color-white: #ffffff;
$color-black: #000000;
$color-mine-shaft: #333333; //Main text color
$color-cod-gray: #1c1c1c; //Mobile footer bg
$color-concrete: #f2f2f2; //Field bg color
$color-tundora: #4d4d4d; //Field dark bg color
$color-gray: #808080; //Field color
$color-mercury: #e5e5e5; //Grey border color
$color-tamarillo: #ad111d; // Red color
$color-carrot-orange: #f28d22; //Orange color
$color-lochmara: #0072bc; //Blue color
$color-chambray: #3b5998; //Facebook color
$color-picton-blue: #2fc2ee; //Twitter color
$color-orange-roughy: #DE4A16; //Google+ color
$color-thunderbird: #cd181f; //Youtube color
$color-leather: #95624f; //Instagram color
$color-bamboo: #e76905; //Rss color
$color-dusty-gray: #999999; //Gray color
$color-dove-gray: #666666; //Gray dark
$color-japanese-laurel: #09980A; //Green color
$color-alabaster: #f7f7f7; //Smoke color
$color-gallery: #ebebeb;
$color-scorpion: #595959;
$color-silver: #cccccc;
