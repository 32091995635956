/* Font VARIABLES */
$font-family: 'ClashGrotesk-Variable', sans-serif;
$font-family-tight: 'ClashGrotesk-Variable', sans-serif;

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 600;
$text-color: black;

/* FONTS paths */
$font-path: '../fonts/';
