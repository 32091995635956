body {
  &.over.reimert, &.over.ubink, &.over.dewilde, &.over.beugel {
    .website-entrance {
      padding-bottom: 20rem;
    }
  }
}

span.widget-icon {
  width: 35px;
  height: 35px;
  color: white;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reimert-entry-text {
  margin-left: 5rem;
  padding-top: 5rem;
  padding-bottom: 1rem;
  max-width: 768px;
  font-weight: 400;
  @include media(max-lg) {
    margin-left: 0rem;
    padding-top: 2rem;
  }
  @include media(max-md) {
    padding-top: 0rem;
  }

  &.text-centered {
    margin: 0 auto;
    text-align: center;
    h2 {
      font-size: 50px;
      @include media(max-md) {
        font-size: 40px;
        line-height: 40px;
        margin-top: 2rem;
      }
    }
  }

  h2 {
    font-size: 60px;
    line-height: 60px;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }

  .intro {
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: 'ClashGrotesk-Variable';
  }
}

.about-image {
  height: 300px;
  display: none;
  @include media(md) {
    height: 100%;
    display: block;
  }

  .image-block {
    height: 100%;
    border-radius: 8px;
  }
}

section.entry-video {
  margin-top: -300px;
}

.over.reimert, .over.ubink, .over.dewilde, .over.beugel {
  .video {
    height: 500px;
  }
  .content {
    font-family: 'Helvetica Neue LT Std';
    h3 {
      font-family: 'ClashGrotesk-Variable';
      font-weight: 500;
      font-size: 1.4rem;
    }
  }
}

section.brand-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 3rem;
  .brandwrapper {
    background-color: #eaeaea;
    border-radius: 8px;
  }
  .brandlogo {
    .logo {
      background-position: 0;
      background-repeat: no-repeat;
      background-size: contain;
      height: 72px;
      background-color: white;
      border-radius: 8px;
      margin-bottom: 1rem;
      padding: 1rem;
      border: 10px solid white;
    }
  }
}

.de_wilde_sierbestrating_website .about-image {
  height: 450px;
  display: block;
}
