/* FORMS */

input, select, textarea {
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  font-size: 14px;
  background-color: white;
}

.form-group {
  .checkbox {
    background-color: white;
    border-radius: 8px;
    border: 1px solid #dfe3e9;
    max-width: 750px;

    label {
      margin-bottom: 0;
      padding: 1rem;
    }

    .checkbox-container {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      flex-wrap: wrap;
      padding: 0.5rem 1rem;
      /* Hide the browser's default checkbox */
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        background-color: #f2f2f2;
        height: 16px;
        display: inline-block;
        left: 0;
        top: 0;
        position: relative;
        border-radius: 4px;
        border: 1px solid #e1e6ea;
        width: 16px;
        margin-right: 10px;
        /* Create the checkmark/indicator (hidden when not checked) */
        &:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Style the checkmark/indicator */
        &:after {
          left: 5px;
          top: 2px;
          width: 4px;
          height: 8px;
          border: solid white;
          border-width: 0 1px 1px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      /* On mouse-over, add a grey background color */
      &:hover input ~ .checkmark {
        background-color: #f2f2f2;
        border-color: #00b2ee;
      }

      /* When the checkbox is checked, add a blue background */
      input:checked ~ .checkmark {
        background-color: #00b2ee;
        border-color: #00b2ee;
      }

      /* Show the checkmark when checked */
      input:checked ~ .checkmark:after {
        display: block;
      }

      input:checked ~ .background {
        background-color: rgba(1, 178, 239, 0.1);
      }

      .background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
        border-radius: 8px;
      }
    }
  }
}

form.contact, form.login {
  input {
    box-sizing: border-box;
    width: 100%;
    border: 2px solid #e0e0e0;
    border-radius: 6px;
    margin-bottom: 15px;
    font-size: 16px;
    padding: 1rem 1rem;
    outline: red;

    &.half {
      width: 100%;
      @include media(sm) {
        width: calc(50% - 7.5px);
      }
    }

    &.first {
      @include media(sm) {
        margin-right: 7.5px;
      }
    }

    &.second {
      @include media(sm) {
        margin-left: 7.5px;
      }
    }

    &:focus {
      outline-color: #e2183a;
    }
  }

  input[type=checkbox] {
    opacity: 0;
    position: absolute;
    width: auto;

    + label {
      position: relative;
      overflow: hidden;
      padding-left: 35px;
      cursor: pointer;
      font-size: 16px;
      display: inline-block;
      color: #9b9b9b;

      a {
        text-decoration: underline;
      }
    }

    + label:before, + label:after {
      content: '';
      position: absolute;
      left: 0;
      z-index: 1;
      -webkit-transition: .2s;
      transition: .2s;
    }

    &:not(:checked) {
      + label:after {
        width: 20px;
        height: 20px;
        border: 2px solid #bcbcbc;
        border-radius: 3px;
        top: 0px;
      }

      + label:before {
        width: 0;
        height: 0;
        border: 3px solid transparent;
        left: 6px;
        top: 10px;
        border-radius: 3px;
        -webkit-transform: rotateZ(37deg);
        transform: rotateZ(37deg);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
      }
    }

    &:checked {
      + label:after {
        top: 0;
        width: 20px;
        height: 20px;
        border: 2px solid red;
        background-color: red;
        z-index: 0;
        border-radius: 2px
      }

      + label:before {
        top: 0;
        left: 1px;
        width: 8px;
        height: 13px;
        margin-top: 3px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: rotateZ(37deg);
        transform: rotateZ(37deg);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
      }
    }
  }
}

.form-container {
  font-family: "Helvetica Neue", sans-serif;

  .form-inner {
    .form-item, .form-group {
      label {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
      }

      input, select, textarea {
        width: 100%;
        padding: 1rem 0;
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.8);
        font-size: 14px;
        background-color: transparent;

        &::placeholder {
          color: rgba(0, 0, 0, 0.8);
          font-size: 16px;
        }
      }

      textarea {

      }

      .widget {
        border: unset;
        border-radius: unset;
        margin: unset;

        .widget {
          width: 100%;
        }

        label {
          display: none;
        }
      }

      .form-item {
        input {
          margin-bottom: .5rem;
        }
      }

      .form-item {
        &:last-child {
          input {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  form {
    > .form-wrapper {
      > div:empty {
        display: none;
      }

      > .form-item, .form-group {
        padding-bottom: 1rem;
      }

      > div:last-child { //TODO: check why this doesn't work
        border-bottom: none !important;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }

    }

    .checkbox {
      background-color: white;
      border-radius: 8px;
      border: 1px solid #dfe3e9;
      max-width: 750px;

      label {
        margin-bottom: 0;
        padding: 1rem;
      }

      .checkbox-container {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 13px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-weight: 300;
        flex-wrap: wrap;
        padding: 0.5rem 1rem;
        /* Hide the browser's default checkbox */
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          background-color: #f2f2f2;
          height: 16px;
          display: inline-block;
          left: 0;
          top: 0;
          position: relative;
          border-radius: 4px;
          border: 1px solid #e1e6ea;
          width: 16px;
          margin-right: 10px;
          /* Create the checkmark/indicator (hidden when not checked) */
          &:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Style the checkmark/indicator */
          &:after {
            left: 5px;
            top: 2px;
            width: 4px;
            height: 8px;
            border: solid white;
            border-width: 0 1px 1px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        /* On mouse-over, add a grey background color */
        &:hover input ~ .checkmark {
          background-color: #f2f2f2;
          border-color: #00b2ee;
        }

        /* When the checkbox is checked, add a blue background */
        input:checked ~ .checkmark {
          background-color: #00b2ee;
          border-color: #00b2ee;
        }

        /* Show the checkmark when checked */
        input:checked ~ .checkmark:after {
          display: block;
        }

        input:checked ~ .background {
          background-color: rgba(1, 178, 239, 0.1);
        }

        .background {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 0;
          border-radius: 8px;
        }
      }
    }
  }

  .form-group {
    .btn {
      padding: 0.4rem 0.8rem;
      font-size: 1rem;
      margin-top: 1rem;

      &:focus {
        outline: none;
      }

      @include media(md) {
        padding: 0.5rem .75rem;
      }
      @include media(lg) {
        padding: .5rem 1.5rem;;
      }

      &:hover {
        text-decoration: none;
      }

      span {
        line-height: 1.5;
      }

      i {
        font-size: 1.5rem;
      }

      svg {
        display: inline-block;
      }
    }
  }
}

.light {
  .form-container {
    margin-top: 2rem;
    background-color: #191919;
    padding: 1.5rem;
    border-radius: 8px;
    .form-inner {
      .form-item, .form-group {
        input, select, textarea {
          border-bottom: 1px solid rgba(255, 255, 255, 0.8);

          &::placeholder {
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
    }
  }
}

ul.help-block {
  background-color: #ad0000;
  border-radius: 4px;
  margin-top: 0.5rem;
  color: white;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  font-size: 14px;
}

div#company_profile_relations_upsell .form-item {
  margin-top: 0.5rem;
}
