/* THUMBNAILS */
.thumbnail {
	padding: 0;
	border-radius: 0;
	border: none;
	&:last-child {
		margin-bottom: 0;
	}
	&.transparent {
		background: transparent;
	}
	img {
		max-width: 100%;
		width: 100%;
	}
	.caption {
		padding-left: 15px;
		padding-right: 15px;
		&.caption-gray {
			background: $color-alabaster;
			@include media('max-md') {
				background: transparent;
			}
		}
	}
	p {
		margin-bottom: 10px;
	}
	ul {
		>li {
			>a {
				font-size: 13px;
			}
		}
	}
}
.thumbnail-title {
	color: $color-tamarillo;
	margin-bottom: 5px;
}
.thumbnail-text {
	font-weight: $font-bold;
}
