/* FOOTER */
footer {
  background-color: #061720;

  a.e-mailbutton {
    font-size: 2rem;
    color: white;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    font-family: 'ClashGrotesk-Variable';
    font-weight: 400;
  }

  ul {
    margin: 0;
    padding: 0;

    a {
      padding-left: 0;
      color: white;
      font-weight: 400;
      font-family: 'ClashGrotesk-Variable';
      text-decoration: none;
      font-size: 22px;
      line-height: 40px;

      span {
        text-decoration: none;
      }
    }

  }

  address .address-block {
    color: white;
    font-style: normal;
  }

  .socials {
    margin-top: 1rem;

    a {
      width: 30px;
      height: 30px;
      color: white;
      border: 1px solid white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
    }
  }

  .formal-menu a {
    color: white;
    margin-right: 2rem;
    margin-bottom: 4rem;
    display: block;
    font-size: 18px;
  }
}

.continue-scrolling {
  position: fixed;
  right: 30px;
  bottom: 35px;
  color: white;
  font-family: Caveat, sans-serif;
  display: flex;
  font-size: 23px;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.4s linear, visibility 0.4s linear;

  div#scrollButton {
    background: #f6923b;
    width: 34px;
    height: 34px;
    font-size: 1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    cursor: pointer;
    @include media(sm) {
      width: 50px;
      height: 50px;
    }
  }
}

.scroll {
  .continue-scrolling {
    opacity: 0;
    visibility: hidden;
  }
}

@include media(max-sm) {
  footer ul li {
    width: 100%;
  }

  footer ul {
    flex-wrap: wrap;
  }

  footer ul.formal-menu li a {
    margin-bottom: 0;
  }

  footer .formal-menu {
    margin-bottom: 2rem;
  }
}
