header.main-header {
  position: fixed;
  width: 100%;
  z-index: 11;
}

.fixed-header-fill {
  height: 120px;
  //@include media('max-lg') {
  //    height: 120px;
  //}
  @include media('max-sm') {
    height: 80px;
  }
}

.site-header {
  font-family: 'ClashGrotesk-Variable';
  height: 120px;
  display: flex;
  transition: all .3s;
  @include media('max-sm') {
    height: 60px;
  }

  .header-favicon {
    display: none;
  }

  .container {
    justify-content: space-between;
  }

  .main-logo {
    display: flex;
    align-items: center;
    justify-content: start;

    a.header-logo {
      display: block;
      width: 100%;

      img {
        transition: all .3s;
        max-height: 48px;
        height: auto;
        @include media('max-sm') {
          max-height: 40px;
          height: auto;
        }
      }
    }
  }

  .header-func-wrapper {
    display: flex;

    .header-search {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      @include media(max-lg) {
        display: none;
      }
    }

    .header-buttons {
      display: flex;
      align-items: center;

      > ul {
        display: flex;
        margin-bottom: 0;

        li {
          margin-right: 1rem;
          position: relative;
          @include media('max-lg') {
            margin-right: .5rem;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .user-dropdown {
          display: none;

          &.show {
            display: block;
            position: absolute;
            background-color: white;
            padding: 1rem;
            z-index: 9;
            box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.2);
            border-radius: 8px 8px 8px 8px;
            margin-top: 10px;
            width: 100%;
          }

          a {
            margin-top: 0;
          }
        }
      }
    }

    .user-login {
      @include media(max-md) {
        height: 41px;
        border: 1px solid;

        span {
          display: none;
        }
      }
    }

    a#hamburger {
      color: black;
      padding: 1rem;
      display: block;
      font-size: 25px;
      cursor: pointer;
    }
  }
}

.main-menu {
  padding: 0 5px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;

  .nav-tabs {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0 2rem;
    border-radius: 50px;
    height: 48px;
    @include media(max-xl) {
      padding: 0 1rem;
    }

    li {
      a {
        border-radius: 12px;
        font-weight: 400;
        padding: 14px;
        font-size: 18px;
        display: block;
        @include media(xl) {
          margin: 0px 5px;
        }
        @include media(max-xl) {
          padding: 14px 11px;
        }
      }

      &.active {
        > a {
          color: #3199d3;
        }
      }

      &.menu-item-has-children {
        position: relative;

        .sub-menu {
          display: none;
          position: absolute;
          left: 100%;
          top: 0;
          width: 260px;
          z-index: 1000;
          margin-top: -2px;
          background-color: white;
          border-radius: 0 0 8px 8px;

          ul.menu_level_2 {
            li a {
              padding: 5px 14px 5px 24px;
              font-size: 16px;
            }
          }
        }

        > a + .sub-menu {
          left: 0rem;
          top: 100%;
        }

        &:hover > .sub-menu {
          display: block;
        }

      }

      .menu-item-has-children:hover .sub-menu {
        display: block;
      }
    }
  }

  .menu_level_0 {
    display: none;
  }

  .integrated-website-menu {
    display: flex;
  }
}

.header-button {
  display: flex;
  align-items: center;
  z-index: 999;
  justify-content: end;

  a#hamburger {
    background-color: white;
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-radius: 12px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #cfd1d2;

    i {
      margin-left: 10px;
    }
  }
}

.second-menu {
  align-items: center;
  justify-content: end;

  .nav-tabs {
    display: flex;
    align-items: end;
  }

  li a {
    font-weight: 500;
    border: 1px solid transparent;
    display: inline-flex;
    align-items: center;
    border-radius: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    cursor: pointer;
    text-decoration: none !important;
    background-color: #f6923b;
    border-color: #f6923b;
    color: white;
    padding: .5rem 1.5rem;
    height: 48px;

    &:hover {
      color: white;
    }
  }
}

.search-form {
  &.header-search-form {
    .form-control {
      background-color: #f2f2f2;
      padding: 0.9rem 50px 0.9rem 1rem;
      border-radius: 8px;
      border: none;
      width: 400px;
      height: 45px;
      font-size: 16px;
      @include media('max-lg') {
        width: 100%;
      }
    }
  }

  &.white-form {
    .form-control {
      background: $color-white;;
    }
  }

  .btn-search {
    position: absolute;
    right: 0;
    height: 100%;
    width: 50px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 15px;
    }
  }
}

body.scroll {
  header .site-header {
    height: 80px;
    transition: all .3s;

    .header-logo {
      display: none;
    }

    .header-favicon {
      display: block;
    }
  }

  .header-button {
    a#hamburger {
      background-color: #f8f8f8;
    }
  }

  header .site-menu {
    nav.main-navigation {
      div > ul > li > a {
        padding: 0.5rem 1rem;
        transition: all .3s;
      }

      .menu_level_1 {
        top: 41px;
      }
    }
  }
}

body:not(.home) {
  header .site-header {
    .header-logo {
      display: none;
    }

    .header-favicon {
      display: block;
    }
  }

  .website-entrance {
    padding-bottom: 10rem;
  }
}

.entry-image .image-block {
  height: 350px;
  border-radius: 8px;
  @include media(max-md) {
    height: 275px;
  }
  @include media(max-sm) {
    height: 200px;
  }
}

section.projects-cta {
  padding-top: 2rem;
}

body {
  &.de_wilde_sierbestrating_website, &.diensten {
    .website-entrance {
      padding-bottom: 0;
    }
  }
}

section.website-entrance {
  background-repeat: no-repeat;
  background-position: top right;
}

body.de_wilde_sierbestrating_website {
  .main-menu {
    .nav-tabs {
      li {
        &.menu-item-has-children {
          .sub-menu {
            width: 220px;
          }
        }
      }
    }
  }
}
