.sidebar {
    max-width: 336px;
}

.widget {
    &.buttons {
        border-radius: 8px;
    }
}

.widget-email {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.5rem;
    font-weight: 500;
    font-family: 'ClashGrotesk-Variable';
    .e-mailbutton {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid black;
        padding-bottom: .5rem;
        font-size: 1.2rem;
    }
}

.widget {
    margin-bottom: 1.5rem;
}


