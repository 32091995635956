section#mobile-navigation {
    position: relative;

    .menu--animatable .slide-menu {
        @include prefix(transition, all 130ms ease-in, webkit moz o);
    }

    .menu--visible {
        pointer-events: auto;

        .slide-menu {
            @include prefix(transform, none, webkit moz ms o);
        }

        &.menu--animatable .slide-menu {
            @include prefix(transition, all 330ms ease-out, webkit moz o);
        }

        &.menu-wrapper:after {
            @include opacity(1);
            pointer-events: auto;
        }
    }

    .slide-menu {
        .logo {
            height: 80px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            margin-bottom: 2rem;
        }
        font-family: 'ClashGrotesk-Variable';
        background-color: #fff;
        position: fixed;
        max-width: 100%;
        width: 100%;
        top: 0;
        right: 0;
        height: 100%;
        justify-content: center;
        @include prefix(transform, translateX(103%), webkit moz ms o);
        @include flexbox;
        @include flex-direction(column);
        will-change: transform;
        z-index: 160;
        pointer-events: auto;
        overflow: auto;
        padding: 2rem;
        @include media('max-sm') {
            font-size: 16px;
        }

        .search-form {
            margin-top: .5rem;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                @include font-size(1.2);
                font-weight: 600;

                &:last-child {
                    a {
                        border: none;
                    }
                }

                &.menu-item-has-children > a {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        margin-left: 5px;
                    }
                }

                a {
                    display: block;
                    color: black;
                    padding: .5rem 0;
                    text-align: center;
                    font-size: 35px;
                    line-height: 46px;
                    @include media('max-sm') {
                        i.fa-chevron-down {
                            display: none;
                        }
                    }
                }

                ul {
                    list-style: none;
                    padding: 0;

                    li {
                        @include font-size(1);
                        padding: 0;
                        font-weight: 400;
                        margin-top: 0 !important;

                        a {
                            border: none;
                            padding: 0.25rem 0;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .menu-wrapper {
        position: fixed;
        right: 0;
        width: 100%;
        top: 0;
        overflow: hidden;
        @include calc(height, '100vh');
        pointer-events: none;
        z-index: 150;
        @include media('max-sm') {
            top: 60px;
            @include calc(height, '100vh - 32px');
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
            @include opacity(0);
            will-change: opacity;
            pointer-events: none;
            @include prefix(transition, opacity 0.3s cubic-bezier(0, 0, 0.3, 1), webkit moz o);
        }
    }
}

body.reimert_groep_website, body.de_wilde_sierbestrating_website {
    section#mobile-navigation .slide-menu ul li {
        a {
            font-size: 25px;
            line-height: 26px;
        }
        ul li {
            a {
                font-size: 20px;
                line-height: 21px;
            }
        }
    }
}

body.int_toolbar {
    section#mobile-navigation {
        .menu-wrapper {
            top: 32px;
            @include calc(height, '100vh - 32px');
            @include media('max-sm') {
                top: 92px;
                @include calc(height, '100vh - 32px');
            }
        }

        .slide-menu {
            top: 32px;
            @include media('max-sm') {
                top: 32px;
            }
        }
    }
}
