.login-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    &.auth {
        background-size: cover;

        .login-header {
            p {
                color: #959599;
                font-size: 18px;
                text-align: left;
            }
        }

        .login-dialog {
            height: auto;
            min-height: auto;
            position: relative;
            width: auto;
            max-width: 480px;
            margin: 0 auto;
        }
    }

    .help {
        padding: 2rem;

        .kader {
            margin-bottom: 0;
        }

        .premium {
            font-size: 20px;
        }

        a {
            text-decoration: underline;

            &:hover, &:focus {
                text-decoration: underline;
            }

        }
    }

    .login {
        position: relative;
        justify-content: center;
        padding: 4rem 0px;
        @include media('max-md') {
            padding:2rem 0px;
        }

        &.auth {

            .form-group {
                margin-bottom: 0.5rem;

                input[type="text"], input[type="password"] {
                    width: 100%;
                    border: 1px solid #d1d1d1;
                    padding: 1rem;
                    font-size: 13px;
                    border-radius: 8px;
                    text-align: center;
                }

                button[type='submit'] {
                    padding: .5rem;
                    border: none;
                    height: auto;
                    width: 100%;
                    line-height: unset;
                    justify-content: center;
                }

                .checkbox {
                    border-radius: 4px;
                    border: none;

                    .checkbox-container input:checked ~ .background {
                        background-color: transparent;
                    }

                    label {
                        padding: 0.5rem 0 !important;
                    }
                }
            }

            .form-item {
                margin-bottom: 0.5rem;
            }
        }
    }

    span.password-reset {
        margin-top: 1rem;
        display: block;
        text-align: center;
        font-size: 14px;
    }
}
