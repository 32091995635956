/* LABEL */
.label {
	display: inline-block;
	position: relative;
	text-align: center;
	padding: 8px 16px;
	border-radius: 0;
	margin-bottom: 0;
	@extend .h5;
	text-transform: uppercase;
	@include media('max-md') {
		padding: 2px 12px;
	}
	@include media('max-sm') {
		font-size: 8px;
	}
	&.label-red {
		background: $color-tamarillo;
	}
	&.label-black {
		background: $color-cod-gray;
	}
	&.label-triangle {
		&:before {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 18px 0 18px 16px;
			border-color: transparent transparent transparent $color-tamarillo;
			content: '';
			position: absolute;
			top: 0;
			right: -16px;
			@include media('max-md') {
				border-width: 12px 0 12px 13px;
				right: -13px;
			}
		}
	}
	&.label-small {
		padding: 4px 12px;
		@include media('max-md') {
			padding: 2px 12px;
		}
	}
}
