/* LIST */
ul {
	>li {
		>a {
			color: $color-mine-shaft;
			&:hover, &:focus {
				color: $color-tamarillo;
			}
		}
	}
	small, .small {
		&.space-right {
			margin-right: 30px;
			vertical-align: middle;
			width: 50px;
			display: inline-block;
		}
	}
}

.list-large {
	>li {
		margin-bottom: 25px;
		&:last-child {
			margin-bottom: 0;
		}
		@include media('max-sm') {
			margin-bottom: 14px;
		}
	}
}

.list-inline {
	margin-left: 0;
	>li {
		padding-left: 15px;
		padding-right: 15px;
		@include media('max-sm') {
			padding-right: 10px;
			padding-left: 10px;
		}
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
}

.list-dotted {
	>li {
		position: relative;
		&:before {
			content: '';
			height: 4px;
			width: 4px;
			display: block;
			border-radius: 50%;
			background-color: $color-mine-shaft;
			position: absolute;
			top: 50%;
			right: -3.5px;
			margin-top: -2px;
		}
		&:last-child {
			&:before {
				content: none;
			}
		}
	}
}

.list-table {
	display: table;
	width: 100%;
	table-layout: fixed;
	li {
		display: table-cell;
	}
	a {
		display: block;
		color: $color-white;
		font-size: 18px;
		padding: 8px 5px;
		&:hover, &:focus {
			color: $color-white;
		}
	}
}

.list-social-login {
	text-align: center;
}

.list-arrow {
	>a {
		display: block;
		position: relative;
		&:hover, &:focus {
			&:before {
				color: $color-mine-shaft;
			}
		}
		&:before {
			position: absolute;
			top: 50%;
			right: 15px;
			margin-top: -10px;
		}
	}
}
.list-arrow-left {
	>li {
		padding-left: 10px;
		>a {
			display: block;
			position: relative;
			padding-left: 20px;
			@include media('max-sm') {
				padding-left: 10px;
				font-size: 11px;
			}
			&:hover, &:focus {
				&:before {
					color: $color-tamarillo;
				}
			}
			&:before {
				position: relative;
				left: 0px;
				left: -20px;
			}
		}
	}
}

.list-inderline {
	span {
		text-decoration: underline;
	}
	a {
		&:before {
			color: $color-mine-shaft;
		}
		&:hover, &:focus {
			text-decoration: none;
		}
	}
}

.list-bullet {
	list-style: disc outside;
	>li {
		list-style: disc inside;
		>a {
			color: $color-lochmara;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
				color: $color-lochmara;
			}
		}
	}
}

.list-social {
	display: table;
	width: 100%;
	table-layout: fixed;
	text-align: center;
	li {
		display: table-cell;
		padding-right: 6px;
		&:last-child {
			padding-right: 0;
		}
	}
	a {
		display: block;
		border: 1px solid $color-mercury;
		margin: 0 auto;
	}
	i {
		line-height: 38px;
		font-size: 18px;
		&.facebook {
			color: $color-chambray;
		}
		&.twitter {
			color:$color-picton-blue;
		}
		&.google {
			color:$color-orange-roughy;
		}
		&.youtube {
			color:$color-thunderbird;
		}
		&.instagram {
			color:$color-leather;
		}
		&.rss {
			color:$color-bamboo;
		}
	}
}

.list-tags {
	li {
		display: inline-block;
		text-align: center;
		margin: 0 3px 3px 0;
		&:last-child {
			margin: 0;
		}
	}
	a {
		display: inline-block;
		border: 1px solid $color-mercury;
		font-size: 10px;
		text-transform: uppercase;
		padding: 1px 6px;
		&:hover, &:focus {
			border-color: $color-tamarillo;
			color: $color-tamarillo;
		}
		&.active {
			border-color: $color-tamarillo;
			color: $color-tamarillo;
		}
	}
}

.list-expanded {
	&.parent {
		>li {
			margin-bottom: 1px;
			&:last-child {
				margin-bottom: 0;
			}
			>a {
				font-weight: $font-bold;
				display: block;
				padding: 8px 10px;
				background: $color-alabaster;
				@include clearfix();
				&:before {
					float: right;
					font-weight: $font-light;
				}
				&.active {
					&:before {
						transform: rotate(90deg);
					}
				}
			}
		}
	}
	&.list-inner {
		>li {
			>a {
				display: block;
				@include clearfix();
				i {
					float: right;
					font-size: 10px;
					color: $color-gray;
					font-weight: $font-light;
				}
				&:hover {
					i {
						color: $color-tamarillo;
					}
				}
			}
		}
	}
	&.sub-list {
		padding-left: 15px;
		margin-bottom: 10px;
		display: none;
		&.sub-list-parent {
			>li {
				border-bottom: 1px dotted $color-mine-shaft;
			}
		}
		>li {
			>a {
				padding: 6px 10px;
				font-weight: $font-bold;
				font-size: 13px;
			}
		}
	}
	&.subsub-list {
		padding-left: 15px;
		margin-bottom: 10px;
		display: none;
		>li {
			>a {
				padding: 4px 10px;
			}
		}
	}
}

.list-sortby {
	li {
		display: inline-block;
		font-weight: $font-bold;
		margin-right: 15px;
		padding-right: 15px;
		border-right: 1px solid $color-dusty-gray;
		line-height: 100%;
		@include media('max-sm') {
			padding-right: 10px;
			margin-right: 10px;
		}
		&:first-child {
			border: none;
			padding-right: 0;
		}
		&:last-child {
			margin-right: 0;
			padding-right: 0;
			border: none;
		}
	}
	a {
		font-weight: $font-regular;
		text-decoration: underline;
		color: $color-lochmara;
		&:hover {
			text-decoration: none;
		}
	}
}

.list-check {
	li {
		font-weight: $font-bold;
		padding: 6px 12px;
		font-size: 14px;
		&:first-child {
			background: $color-alabaster;
		}
	}
	a {
		font-size: 13px;
		border-bottom: 1px dotted $color-mine-shaft;
		display: block;
		position: relative;
		&.active {
			&:before {
				font-family: 'fontello';
				float: right;
				margin-top: -2px;
			}
		}
	}
}

ol {
    list-style: auto;
    margin-left: 2rem;
    margin-bottom: 1rem;
}









