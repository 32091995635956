@import 'colors';
@import 'typography';

//Body VARIABLES
$main-text-color: #000;
$body-font-size: 16px;
$body-line-height: 24px;
$body-main-font: $font-family;
$body-tight-font: $font-family-tight;

/* Form VARIABLES */
$input-color-placeholder: $color-gray;
$input-font-size-placeholder: $body-font-size;
$input-line-height-placeholder: $body-line-height;

//Global VARIABLES
$border-radius: 25px;
