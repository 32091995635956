.dossiers {
    h2.dossier-heading {
        height: 1px;
        background-color: #d8d8d8;
        margin-top: 1rem;
        margin-bottom: 1rem;
        position: relative;
        a {
            position: absolute;
            background-color: white;
            text-transform: uppercase;
            font-weight: bold;
            color: #9a9a9a;
            font-size: 12px;
            border-radius: 50px;
            line-height: 25px;
            height: 32px;
            padding: 3px 1rem;
            margin-top: -16px;
            border: 1px solid #d8d8d8;
            text-decoration: none;
        }
    }
}
