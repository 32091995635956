.content-wrapper {

}

.overview-item {
  .article-top-image {
    border-radius: 8px;

    &:hover {
      filter: none;
    }
  }

  a, .intro {
    text-decoration: none;
  }

  &.item-inline {
    a, .intro {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}


.grid-logos {
  .article-top-image {
    background-size: 80%;
    background-repeat: no-repeat;
    @include media('max-xl') {
      background-size: 80%;
    }
    @include media('max-2xl') {
      background-size: 70%;
    }
  }

  a, .intro {
    color: white;
  }
}


.background-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.4) 100%);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.overview-item:hover {
  > a .background-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.2) 100%);
  }
}


