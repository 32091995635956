table {
    background-color: #EAEAEA;
    padding: 1rem;
    font-family: 'ClashGrotesk-Variable';
    font-size: 1.2rem;
    border-radius: 8px;
    tr {
        td {
            padding: .5rem;
            p {
                margin: 0;
            }
            &:last-child {
                border: none;
            }
        }
        &:last-child {
            border: none;
        }
    }
}

table tr:first-child td {
    padding-top: 2rem;
}

table tr:last-child td {
    padding-bottom: 2rem;
}

table tr td:first-child {
    padding-left: 2rem;
}

table tr td:last-child {
    padding-right: 2rem;
}

table strong {
    font-weight: 500;
}
