/* Reset styles */
* {
	&:hover, &:focus, &:active {
		outline: none !important;
	}
}

a:hover, a:focus, a:visited {
	text-decoration: none;
	outline: none;
}

hr {
	border-color: $color-mercury;
	@include media('max-sm') {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	&.hr-small {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
}

/* columns of same height styles */

.same-height {
	display:flex;
	@include media('max-md') {
		display: block;
	}
}
